import { ProductCategoryEnum, ProductSubCategoryEnum } from '_api-client';
import { HStack, Text } from '@chakra-ui/react';
import { Button } from 'components/ui/button';
import {
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
} from 'components/ui/dialog';
import { useFormik } from 'formik';
import { useRef } from 'react';
import {
  ProductCategorizeAndApprovedValidationSchema,
  ProductCategorizeAndApprovedValidationType,
  ProductCategoryReadWithTitle,
  ProductSubCategoryReadWithTitle,
} from 'types/products';
import { pluralize } from 'utils';

import { CategorySubcategorySelector } from './product-classifier/category-subcategory-selector';

interface CategorizeModalProps {
  isOpen: boolean;
  onClose: () => void;
  categories: ProductCategoryReadWithTitle[];
  subcategoriesByCategory: Record<ProductCategoryEnum, ProductCategoryReadWithTitle>;
  onSubmit: (values: ProductCategorizeAndApprovedValidationType) => void;
  selectedCount: number;
  setCheckedRows: (value: string[]) => void;
}

/* checked products categorize update and approved modal */
export const CategorizeModal = ({
  isOpen,
  onClose,
  categories,
  subcategoriesByCategory,
  onSubmit,
  selectedCount,
  setCheckedRows,
}: CategorizeModalProps) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const formik = useFormik({
    initialValues: {
      new_category: '' as ProductCategoryEnum,
      new_subcategory: '' as ProductSubCategoryEnum,
    },
    validationSchema: ProductCategorizeAndApprovedValidationSchema,
    onSubmit: values => {
      onSubmit(values);
      setCheckedRows([]);
      onClose();
    },
  });

  const getSubcategoryItems = () => {
    return (formik.values.new_category
      ? subcategoriesByCategory[formik.values.new_category]?.subcategories || []
      : []) as unknown as ProductSubCategoryReadWithTitle[];
  };

  return (
    <DialogRoot open={isOpen} placement="center">
      <DialogBackdrop />
      <DialogContent colorPalette="blue" ref={contentRef}>
        <DialogHeader>
          <DialogTitle>Categorize and Approve Products</DialogTitle>
        </DialogHeader>
        <DialogCloseTrigger onClick={onClose} />
        <form onSubmit={formik.handleSubmit}>
          <DialogBody width={'100%'}>
            <Text fontSize={'sm'} pb={4}>
              Assign category and subcategory to {selectedCount > 1 && 'all the'} {selectedCount} selected{' '}
              {pluralize('product', selectedCount)}.
            </Text>
            <HStack gap={4} justifyContent={'space-between'} alignItems={'flex-end'}>
              <CategorySubcategorySelector
                categories={categories}
                getSubcategoryItems={getSubcategoryItems}
                values={formik.values}
                errors={formik.errors}
                touched={formik.touched}
                setFieldValue={formik.setFieldValue}
              />
            </HStack>
          </DialogBody>
          <DialogFooter gap={3}>
            <Button variant={'secondary-outline'} color={'secondary'} onClick={onClose}>
              Cancel
            </Button>
            <Button
              type="submit"
              variant={'solid'}
              width="90px"
              disabled={!formik.isValid || !formik.dirty}
              loading={formik.isSubmitting}
            >
              Save
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </DialogRoot>
  );
};
