import { Badge } from '@chakra-ui/react';
import { ProductStatusEnum } from 'types/shared-types';
import { getHumanReadableString } from 'utils/enum-helpers';

type ProductStatusBadgeProps = {
  status: ProductStatusEnum;
};

export default function ProductStatusBadge({ status }: ProductStatusBadgeProps) {
  switch (status) {
    case ProductStatusEnum.APPROVED:
      return (
        <Badge colorPalette={'green'} width={'80px'} height={'26px'}>
          {getHumanReadableString(status)}
        </Badge>
      );
    case ProductStatusEnum.PARTIALLY_APPROVED:
      return (
        <Badge
          backgroundImage="linear-gradient(90deg, rgba(9, 192, 206, 0.15) 0%, rgba(98, 122, 230, 0.15) 42.96%, rgba(161, 116, 235, 0.15) 100%)"
          width={'134px'}
          height={'26px'}
        >
          {getHumanReadableString(status)}
        </Badge>
      );
    case ProductStatusEnum.PENDING:
      return (
        <Badge colorPalette={'gray'} width={'95px'} height={'26px'}>
          Unapproved
        </Badge>
      );
    default:
      return null;
  }
}
