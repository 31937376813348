import { ProductStatusEnum } from '_api-client';
import { TableFilterPopover } from 'components/filter';
import { useState } from 'react';

import { FilterContent } from './filter-content';

type FilterButtonProps = {
  status: ProductStatusEnum[] | undefined;
  setStatus: (status: ProductStatusEnum[] | undefined) => void;
};

export const FilterButton = ({ status, setStatus }: FilterButtonProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const hasFilter = !!status;

  return (
    <TableFilterPopover
      hasFilter={hasFilter}
      open={open}
      onOpenChange={({ open }) => setOpen(open)}
      popoverContentProps={{ portalled: false }}
    >
      <FilterContent status={status} setStatus={setStatus} onClose={() => setOpen(false)} />
    </TableFilterPopover>
  );
};
