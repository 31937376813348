import { Flex, HStack, Input, Stack, Textarea } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createProduct, PRODUCT_STATE_KEY, updateProducts } from 'apis/product-apis';
import { Button } from 'components/ui/button';
import {
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
} from 'components/ui/dialog';
import { Field } from 'components/ui/field';
import { NativeSelectField, NativeSelectRoot } from 'components/ui/native-select';
import { SOURCE_CONSTANTS } from 'constants/source-constants';
import { useFormik } from 'formik';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useOrg } from 'hooks/useOrg';
import {
  ProductCategoryEnum,
  ProductCategoryReadWithTitle,
  ProductCreate,
  ProductCreateSchema,
  ProductUpdate,
  ProductUpdateSchema,
} from 'types/products';
import { ProductInstance, ProductStatusEnum } from 'types/shared-types';
import { getHumanReadableString } from 'utils/enum-helpers';
import { getProductCategoryTitle, getProductSubcategoryTitle } from 'utils/product-utils';

type CreateProductFormProps = {
  isOpen: boolean;
  onClose: () => void;
  categories: ProductCategoryReadWithTitle[];
  subcategoriesByCategory: Record<ProductCategoryEnum, ProductCategoryReadWithTitle>;
  data?: ProductInstance | undefined;
};

const FORM_INITIAL_VALUES = {
  external_id: '',
  name: '',
  description: '',
  source: 'OTHER',
  status: ProductStatusEnum.APPROVED,
  product_category: ProductCategoryEnum.UNKNOWN,
  product_subcategory: '',
  tax_exempt: false,
};

export const CreateProductForm = ({
  isOpen,
  onClose,
  subcategoriesByCategory,
  categories,
  data: savedData,
}: CreateProductFormProps) => {
  const { orgId } = useOrg();
  const queryClient = useQueryClient();
  const { handleSuccessNotification } = useHandleNotification();

  const { mutate: createProductMutation, isPending: isCreateProductPending } = useMutation({
    mutationFn: (payload: ProductCreate) => {
      return createProduct(orgId, payload);
    },
    onSuccess: () => {
      onCloseModal();
      handleSuccessNotification('Product successfully created.');
      queryClient.invalidateQueries({
        queryKey: [PRODUCT_STATE_KEY, orgId],
      });
    },
  });

  const { mutate: updateProductMutation, isPending: isUpdateProductPending } = useMutation({
    mutationFn: (payload: ProductUpdate) => {
      return updateProducts(payload.id, orgId, payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [PRODUCT_STATE_KEY] });
      handleSuccessNotification('Product updated successfully.');
      onCloseModal();
    },
  });

  const { values, handleChange, setFieldValue, handleSubmit, resetForm, errors, touched } = useFormik({
    initialValues: savedData || FORM_INITIAL_VALUES,
    validationSchema: savedData ? ProductUpdateSchema : ProductCreateSchema,
    enableReinitialize: true,
    onSubmit: values => {
      if (savedData && 'id' in values && values.id) {
        updateProductMutation(values);
      } else {
        createProductMutation(values);
      }
    },
  });

  const onCloseModal = () => {
    resetForm();
    onClose();
  };
  const dropdownItems = SOURCE_CONSTANTS?.map((item: any) => ({ label: getHumanReadableString(item), value: item }));

  return (
    <DialogRoot
      size={'md'}
      open={isOpen}
      onOpenChange={({ open }) => {
        if (!open) {
          onCloseModal();
        }
      }}
    >
      <DialogBackdrop />
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{savedData ? 'Edit Product' : 'New Product'}</DialogTitle>
        </DialogHeader>
        <DialogBody>
          <form noValidate>
            <Stack gap={'16px'}>
              <HStack>
                <Field
                  label="ID"
                  invalid={!!(errors.external_id && touched.external_id)}
                  errorText={errors.external_id}
                  required
                >
                  <Input
                    id="external_id"
                    type="external_id"
                    name="external_id"
                    value={values.external_id}
                    onChange={handleChange}
                  />
                </Field>
                <Field label="Name" invalid={!!(errors.name && touched.name)} errorText={errors.name} required>
                  <Input id="name" type="name" name="name" value={values.name} onChange={handleChange} />
                </Field>
              </HStack>

              <Field
                label="Description"
                invalid={!!(errors.description && touched.description)}
                errorText={errors.description}
              >
                <Textarea
                  id="description"
                  name="description"
                  value={values.description ?? ''}
                  onChange={handleChange}
                  borderRadius={'1px'}
                  size={'sm'}
                />
              </Field>
              <HStack align={'baseline'}>
                <Field
                  label="Category"
                  invalid={!!(errors.product_category && touched.product_category)}
                  errorText={errors.product_category}
                  required
                >
                  <NativeSelectRoot>
                    <NativeSelectField
                      placeholder="Select option"
                      id="product_category"
                      name="product_category"
                      value={values.product_category}
                      onChange={evt => {
                        handleChange(evt);
                        setFieldValue('product_subcategory', '');
                      }}
                      color={values.product_category === 'UNKNOWN' ? '#B7BAC5' : '#5F647B'}
                    >
                      {categories.map(({ name }) => {
                        const title = getProductCategoryTitle(name);
                        return (
                          <option key={name} value={name} title={title}>
                            {title}
                          </option>
                        );
                      })}
                    </NativeSelectField>
                  </NativeSelectRoot>
                </Field>
                <Field
                  label="Subcategory"
                  invalid={!!(errors.product_subcategory && touched.product_subcategory)}
                  errorText={errors.product_subcategory}
                  required
                >
                  <NativeSelectRoot>
                    <NativeSelectField
                      id="product_subcategory"
                      name="product_subcategory"
                      value={values.product_subcategory}
                      onChange={handleChange}
                      placeholder="Select Option"
                      color={values.product_subcategory === 'UNKNOWN' ? '#B7BAC5' : '#5F647B'}
                    >
                      {subcategoriesByCategory[values.product_category]?.subcategories.map(({ name }) => {
                        const title = getProductSubcategoryTitle(name);
                        return (
                          <option key={name} value={name} title={title}>
                            {title}
                          </option>
                        );
                      })}
                    </NativeSelectField>
                  </NativeSelectRoot>
                </Field>
              </HStack>
              <HStack alignItems="center" gap={4}>
                <Field label="Source" required>
                  <NativeSelectRoot>
                    <NativeSelectField
                      placeholder="Select Source"
                      id="source"
                      name="source"
                      value={values.source}
                      onChange={handleChange}
                    >
                      {dropdownItems.map(({ label, value }) => (
                        <option key={value} value={value}>
                          {label}
                        </option>
                      ))}
                    </NativeSelectField>
                  </NativeSelectRoot>
                </Field>
              </HStack>
            </Stack>
          </form>
        </DialogBody>
        <DialogFooter>
          <Flex gap={2}>
            <Button variant={'outline'} color={'secondary'} onClick={onCloseModal}>
              Cancel
            </Button>
            <Button
              variant={'solid'}
              colorPalette="blue"
              width={'90px'}
              onClick={() => handleSubmit()}
              loading={isCreateProductPending || isUpdateProductPending}
            >
              Save
            </Button>
          </Flex>
        </DialogFooter>
        <DialogCloseTrigger />
      </DialogContent>
    </DialogRoot>
  );
};
