import { ProductCategoryEnum } from '_api-client';
import { CheckboxGroup, Flex, HStack, Stack, Text, VStack } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  PRODUCT_CATEGORY_SUBCATEGORIES_OVERVIEW_STATE_KEY,
  PRODUCT_STATE_KEY,
  updateProductCategorySubcategory,
} from 'apis/product-apis';
import { ArrowRight } from 'components/icons';
import { Button } from 'components/ui/button';
import { Checkbox } from 'components/ui/checkbox';
import {
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
} from 'components/ui/dialog';
import { useFormik } from 'formik';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useOrg } from 'hooks/useOrg';
import { ProductStatusEnum } from 'schema/types-schema.d';
import {
  ProductCategoryReadWithTitle,
  ProductCategorySubcategoryOverview,
  ProductCategoryUpdate,
  ProductCategoryUpdateValidationSchema,
  ProductSubCategoryEnum,
  ProductSubCategoryReadWithTitle,
} from 'types/products';
import { getProductCategoryTitle, getProductSubcategoryTitle } from 'utils/product-utils';

import { CategorySubcategorySelector } from '../product-classifier/category-subcategory-selector';

interface ClassifierUpdateModalProps {
  isOpen: boolean;
  onClose: () => void;
  existingData: ProductCategorySubcategoryOverview;
  categories: ProductCategoryReadWithTitle[];
  subcategoriesByCategory: Record<ProductCategoryEnum, ProductCategoryReadWithTitle>;
}

/*
This modal is used to bulk update the category and subcategory number of products.
*/
export const ClassifierUpdateModal = ({
  isOpen,
  onClose,
  existingData,
  categories,
  subcategoriesByCategory,
}: ClassifierUpdateModalProps) => {
  const queryClient = useQueryClient();
  const { orgId } = useOrg();
  const { handleSuccessNotification } = useHandleNotification();
  const { mutateAsync: bulkUpdateProductCategorySubcategory, isPending: isUpdating } = useMutation({
    mutationFn: async (payload: ProductCategoryUpdate) => {
      await updateProductCategorySubcategory(orgId, payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [PRODUCT_CATEGORY_SUBCATEGORIES_OVERVIEW_STATE_KEY] });
      queryClient.invalidateQueries({ queryKey: [PRODUCT_STATE_KEY] });
      handleSuccessNotification(
        'Products will be updated shortly. Please allow up to a minute, depending on how many products you updated.'
      );
      onClose();
    },
  });

  const { values, handleSubmit, setFieldValue, isValid, errors, touched, dirty } = useFormik({
    initialValues: {
      new_category: '' as ProductCategoryEnum,
      new_subcategory: '' as ProductSubCategoryEnum,
      status_list: [ProductStatusEnum.PARTIALLY_APPROVED, ProductStatusEnum.PENDING] as ProductStatusEnum[],
    },
    validationSchema: ProductCategoryUpdateValidationSchema,
    enableReinitialize: true,
    onSubmit: async values => {
      const payload: ProductCategoryUpdate = {
        ...values,
        existing_category: existingData.category,
        existing_subcategory: existingData.subcategory,
      };
      await bulkUpdateProductCategorySubcategory(payload);
    },
  });

  const getSubcategoryItemsByCategoryId = () => {
    const category = values.new_category as ProductCategoryEnum;
    return (subcategoriesByCategory?.[category]?.subcategories as unknown as ProductSubCategoryReadWithTitle[]) ?? [];
  };

  const STATUS_OPTIONS = [
    { value: ProductStatusEnum.APPROVED, label: 'Approved' },
    { value: ProductStatusEnum.PENDING, label: 'Unapproved' },
    { value: ProductStatusEnum.PARTIALLY_APPROVED, label: 'Partially Approved' },
  ];

  return (
    <DialogRoot
      open={isOpen}
      onOpenChange={({ open }) => {
        if (!open) {
          onClose();
        }
      }}
      size="xl"
      placement="center"
    >
      <DialogBackdrop />
      <DialogContent>
        <DialogHeader fontSize="lg" fontWeight="bold">
          <DialogTitle>Modify Product Classification</DialogTitle>
        </DialogHeader>
        <DialogCloseTrigger />

        <DialogBody>
          <VStack gap={4} justifyContent={'flex-start'} alignItems={'flex-start'}>
            <Text>
              The existing category and subcategory will be updated to the new ones you select. This change will apply
              to all the products with the statuses you select below.
            </Text>
            <VStack justifyContent={'flex-start'} alignItems={'flex-start'}>
              <Text fontWeight={'medium'}>Select product statuses to choose products for modification</Text>
              <CheckboxGroup
                name="status_list"
                defaultValue={[ProductStatusEnum.PARTIALLY_APPROVED, ProductStatusEnum.PENDING]}
                onValueChange={(value: string[]) => setFieldValue('status_list', value as ProductStatusEnum[])}
                value={values.status_list}
              >
                <Stack gap={2}>
                  {STATUS_OPTIONS.map(({ value, label }) => (
                    <Checkbox key={value} value={value}>
                      <Text fontWeight={'normal'}>{label}</Text>
                    </Checkbox>
                  ))}
                </Stack>
              </CheckboxGroup>
            </VStack>
            <Flex w="full" padding={4} alignItems="flex-start" gap="4" borderRadius="4px" border="1px solid #EFEFF3">
              <VStack w="50%" alignItems="flex-start" justifyContent={'flex-start'}>
                <Text fontSize="xs" fontWeight="bold" color="#4B5169">
                  Existing
                </Text>
                <HStack gap={4} width={'100%'} justifyContent={'space-between'} alignItems={'flex-end'}>
                  <HStack width={'100%'} gap={4}>
                    <VStack alignItems={'flex-start'} gap={4}>
                      <Text fontWeight={'bold'}>Category</Text>
                      <Text>{getProductCategoryTitle(existingData.category)}</Text>
                    </VStack>
                    <VStack alignItems={'flex-start'} gap={4}>
                      <Text fontWeight={'bold'}>Subcategory</Text>
                      <Text>{getProductSubcategoryTitle(existingData.subcategory)}</Text>
                    </VStack>
                  </HStack>
                  <HStack justifyContent={'flex-end'}>
                    <ArrowRight />
                  </HStack>
                </HStack>
              </VStack>

              <VStack w="50%" alignItems="flex-start">
                <Text fontSize="xs" fontWeight="bold" color="#4B5169">
                  New
                </Text>
                <HStack gap={4} width={'100%'} justifyContent={'flex-start'} alignItems={'flex-end'}>
                  <CategorySubcategorySelector
                    categories={categories}
                    getSubcategoryItems={getSubcategoryItemsByCategoryId}
                    values={values}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                  />
                </HStack>
              </VStack>
            </Flex>
          </VStack>
        </DialogBody>

        <DialogFooter gap={4}>
          <Button variant={'secondary-outline'} color={'secondary'} onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant={'solid'}
            colorPalette="blue"
            width="90px"
            disabled={!isValid || !dirty}
            onClick={() => handleSubmit()}
            loading={isUpdating}
          >
            Save
          </Button>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};
