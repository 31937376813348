import { ProductStatusEnum } from '_api-client';
import { Box, CheckboxGroup, HStack, Stack, Text } from '@chakra-ui/react';
import { AccordionItem, AccordionItemContent, AccordionItemTrigger, AccordionRoot } from 'components/ui/accordion';
import { Button } from 'components/ui/button';
import { Checkbox } from 'components/ui/checkbox';
import { useState } from 'react';

type FilterContentProps = {
  onClose: () => void;
  status: ProductStatusEnum[] | undefined;
  setStatus: (status: ProductStatusEnum[] | undefined) => void;
};

export const FilterContent = ({ onClose, status, setStatus }: FilterContentProps) => {
  const [accordionIndex, setAccordionIndex] = useState<string[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<ProductStatusEnum[] | undefined>(status);

  const handleSave = () => {
    setStatus(selectedStatus);
    onClose();
  };

  const handleClear = () => {
    setStatus(undefined);
    setSelectedStatus(undefined);
    onClose();
  };

  const hasFilter = !!selectedStatus;

  return (
    <Stack overflow={'auto'} color={'gray.900'}>
      <HStack position={'sticky'} top={0} bgColor={'#fff'} zIndex={1} justifyContent={'space-between'}>
        <Text fontSize={'18px'} fontWeight={500}>
          Filters
        </Text>
        {hasFilter && (
          <Text fontSize={'12px'} color={'#4285F4'} cursor={'pointer'} onClick={handleClear}>
            Clear All Filters
          </Text>
        )}
      </HStack>
      <Stack>
        <AccordionRoot variant="plain" multiple value={accordionIndex} onValueChange={e => setAccordionIndex(e.value)}>
          <AccordionItem key="status__in" value="status__in">
            <AccordionItemTrigger>
              <Box as="span" fontSize={'sm'} fontWeight={'medium'} flex="1" textAlign="left">
                Status
              </Box>
            </AccordionItemTrigger>
            <AccordionItemContent gap={2} mb={2}>
              <CheckboxGroup
                value={selectedStatus ? selectedStatus : []}
                onValueChange={values => setSelectedStatus(values as ProductStatusEnum[] | undefined)}
              >
                {[
                  { key: 'APPROVED', label: 'Approved' },
                  { key: 'PENDING', label: 'Unapproved' },
                  { key: 'PARTIALLY_APPROVED', label: 'Partially Approved' },
                ].map(({ key, label }) => (
                  <Checkbox key={key} value={key}>
                    {label}
                  </Checkbox>
                ))}
              </CheckboxGroup>
            </AccordionItemContent>
          </AccordionItem>
        </AccordionRoot>
        <HStack direction="row" justifyContent={'space-around'} gap="18px" mt={4}>
          <Button variant="outline" color="secondary" onClick={onClose} width={'132px'} height={'32px'}>
            Cancel
          </Button>
          <Button variant="solid" onClick={handleSave} width={'132px'} height={'32px'}>
            Apply
          </Button>
        </HStack>
      </Stack>
    </Stack>
  );
};
