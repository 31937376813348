import KSearchInput from 'components/SearchInput';
import { useDebounce } from 'hooks/useDebounce';
import { UseTableFiltersType } from 'hooks/useTableFilters';
import { useState } from 'react';

type ProductSearchProps = {
  tableFilters: UseTableFiltersType;
};

const ProductSearch = ({ tableFilters }: ProductSearchProps) => {
  const { query, setFilters } = tableFilters;
  const [value, setValue] = useState<string>(query || '');

  const debouncedRequest = useDebounce(() => {
    setFilters({ query: value });
  });

  const handleInputChange = (query: string) => {
    setValue(query);

    debouncedRequest();
  };

  return <KSearchInput query={value} setQuery={handleInputChange} styles={{ width: '280px' }} />;
};

export default ProductSearch;
