import { Box, createListCollection, Text, VStack } from '@chakra-ui/react';
import { Field } from 'components/ui/field';
import {
  SelectContent,
  SelectItem,
  SelectRoot,
  SelectTrigger,
  SelectValueText,
  SubCategorySelectItem,
} from 'components/ui/select';
import { FormikErrors, FormikTouched } from 'formik';
import { useMemo } from 'react';
import {
  ProductCategorizeAndApprovedValidationType,
  ProductCategoryReadWithTitle,
  ProductSubCategoryReadWithTitle,
} from 'types/products';
import { getProductSubcategoryTitle } from 'utils/product-utils';

interface CategorySubcategorySelectorProps {
  categories: ProductCategoryReadWithTitle[];
  getSubcategoryItems: () => ProductSubCategoryReadWithTitle[];
  values: ProductCategorizeAndApprovedValidationType;
  errors: FormikErrors<ProductCategorizeAndApprovedValidationType>;
  touched: FormikTouched<ProductCategorizeAndApprovedValidationType>;
  setFieldValue: (field: string, value: any) => void;
}

export const CategorySubcategorySelector = ({
  categories,
  getSubcategoryItems,
  values,
  errors,
  touched,
  setFieldValue,
}: CategorySubcategorySelectorProps) => {
  const categoriesCollection = useMemo(() => {
    return createListCollection({
      items: categories,
      itemToString: ({ title }) => title,
      itemToValue: ({ name }) => name,
    });
  }, [categories]);

  const subCategoriesCollection = useMemo(() => {
    return createListCollection({
      items: getSubcategoryItems(),
      itemToString: ({ title }) => title,
      itemToValue: ({ name }) => name,
    });
  }, [getSubcategoryItems]);

  return (
    <>
      <VStack alignItems={'flex-start'} width={'100%'}>
        <Field
          label="Category"
          invalid={!!(errors.new_category && touched.new_category)}
          errorText={errors.new_category}
          required
        >
          <SelectRoot
            collection={categoriesCollection}
            value={values.new_category ? [values.new_category] : undefined}
            onValueChange={({ value }) => {
              setFieldValue('new_category', value[0]);
              setFieldValue('new_subcategory', undefined);
            }}
          >
            <SelectTrigger>
              <SelectValueText placeholder="Select" />
            </SelectTrigger>
            <SelectContent portalled={false}>
              {categoriesCollection.items.map(item => (
                <SelectItem key={item.name} item={item}>
                  {item.title}
                </SelectItem>
              ))}
            </SelectContent>
          </SelectRoot>
        </Field>
      </VStack>
      <VStack alignItems={'flex-start'} width={'100%'}>
        <Field
          label="Subcategory"
          invalid={!!(errors.new_subcategory && touched.new_subcategory)}
          errorText={errors.new_subcategory}
          required
        >
          <SelectRoot
            collection={subCategoriesCollection}
            value={values.new_subcategory ? [values.new_subcategory] : undefined}
            onValueChange={({ value }) => {
              setFieldValue('new_subcategory', value[0]);
            }}
          >
            <SelectTrigger>
              <SelectValueText placeholder="Select" />
            </SelectTrigger>
            <SelectContent portalled={false} minWidth={'196px'} maxWidth={'100%'} justifyContent={'flex-start'}>
              {subCategoriesCollection.items.map(item => (
                <SubCategorySelectItem
                  tooltip={
                    <VStack p={'8px'} gap={4}>
                      <Box w="full">
                        <Text fontSize={'xs'}>{!!item.description && 'Description:'}</Text>
                        <Text fontSize={'xs'}>{item.description}</Text>
                      </Box>
                      <Box w="full">
                        <Box minW={'60px'} mr={'30px'}>
                          <Text fontSize={'xs'}>{!!item.example && 'Examples:'}</Text>
                          <Text fontSize={'xs'}>{item.example}</Text>
                        </Box>
                      </Box>
                    </VStack>
                  }
                  key={item.name}
                  item={item}
                >
                  {getProductSubcategoryTitle(item.name)}
                </SubCategorySelectItem>
              ))}
            </SelectContent>
          </SelectRoot>
        </Field>
      </VStack>
    </>
  );
};
