import { useMutation, useQueryClient } from '@tanstack/react-query';
import { bulkClassifyProducts, PRODUCT_CLASSIFICATION_STATE_KEY } from 'apis/product-apis';
import { useOrg } from 'hooks/useOrg';

export const useBulkClassifyProducts = () => {
  const queryClient = useQueryClient();
  const { orgId } = useOrg();
  return useMutation<unknown, unknown>({
    mutationFn: async () => {
      await bulkClassifyProducts(orgId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [PRODUCT_CLASSIFICATION_STATE_KEY, orgId, 'classification', 'status'],
      });
    },
  });
};
