import { TableFilterPopover } from 'components/filter';
import { UseTableFiltersType } from 'hooks/useTableFilters';
import { useState } from 'react';

import { FilterContent } from './filter-content';

type FilterButtonProps = {
  tableFilters: UseTableFiltersType;
};

export const FilterButton = ({ tableFilters }: FilterButtonProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const { status__in, product_category__in, source__in } = tableFilters;
  const hasFilter = !!status__in || !!product_category__in || !!source__in;
  return (
    <TableFilterPopover hasFilter={hasFilter} open={open} onOpenChange={({ open }) => setOpen(open)}>
      <FilterContent
        tableFilters={tableFilters}
        onClose={() => {
          setOpen(false);
        }}
      />
    </TableFilterPopover>
  );
};
