import { Text } from '@chakra-ui/react';
import { AIIconButton } from 'components/icons';
import { Button } from 'components/ui/button';
import {
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
} from 'components/ui/dialog';

interface ClassifyConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isClassifyPending: boolean;
}

const ClassifyConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
  isClassifyPending,
}: ClassifyConfirmationModalProps) => {
  return (
    <DialogRoot
      open={isOpen}
      onOpenChange={({ open }) => {
        if (!open) {
          onClose();
        }
      }}
      placement="center"
      size={'md'}
      restoreFocus={false}
    >
      <DialogBackdrop />
      <DialogContent maxW={'468px'}>
        <DialogHeader fontSize={'lg'} fontWeight={'medium'}>
          <DialogTitle>Classify Product</DialogTitle>
        </DialogHeader>
        <DialogCloseTrigger />
        <DialogBody>
          <Text>
            Kintsugi Intelligence will reclassify your partially approved products and unapproved products on this page.
          </Text>
        </DialogBody>
        <DialogFooter gap={3}>
          <Button variant="outline" color="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant={'solid'}
            width={'90px'}
            onClick={onConfirm}
            bgGradient={'linear(to-r, #04C3CC, #527BE4, #A373EB)'}
            loading={isClassifyPending}
          >
            <AIIconButton />
            Submit
          </Button>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};

export default ClassifyConfirmationModal;
