import { ProductStatusEnum } from '_api-client';
import { SourceEnum } from 'schema/types-schema';
import { array, boolean, InferType, mixed, object, string } from 'yup';

export type ProductStatus = 'APPROVED' | 'PARTIALLY_APPROVED' | 'PENDING';

export enum ProductCategoryEnum {
  PHYSICAL = 'PHYSICAL',
  DIGITAL = 'DIGITAL',
  SERVICE = 'SERVICE',
  UNKNOWN = 'UNKNOWN',
  MISCELLANEOUS = 'MISCELLANEOUS',
}

export enum ProductSubCategoryEnum {
  UNKNOWN = 'UNKNOWN',
  SAAS = 'SAAS',
  DIGITAL_GENERAL = 'DIGITAL_GENERAL',
  B2B_SAAS = 'B2B_SAAS',
  IAAS = 'IAAS',
  SOFTWARE_ON_PERSONAL_PROPERTY = 'SOFTWARE_ON_PERSONAL_PROPERTY',
  SOFTWARE_DOWNLOADED = 'SOFTWARE_DOWNLOADED',
  CUSTOM_SOFTWARE_ON_PERSONAL_PROPERTY = 'CUSTOM_SOFTWARE_ON_PERSONAL_PROPERTY',
  CUSTOM_SOFTWARE_DOWNLOADED = 'CUSTOM_SOFTWARE_DOWNLOADED',
  CUSTOMIZATION_OF_SOFTWARE = 'CUSTOMIZATION_OF_SOFTWARE',
  B2C_SAAS = 'B2C_SAAS',
  SERVICE_GENERAL = 'SERVICE_GENERAL',
  PROFESSIONAL_SERVICE = 'PROFESSIONAL_SERVICE',
  TANGIBLE_PROPERTY_SERVICE = 'TANGIBLE_PROPERTY_SERVICE',
  REAL_PROPERTY_SERVICE = 'REAL_PROPERTY_SERVICE',
  BUSINESS_SERVICE = 'BUSINESS_SERVICE',
  PERSONAL_SERVICE = 'PERSONAL_SERVICE',
  AMUSEMENT_SERVICE = 'AMUSEMENT_SERVICE',
  MEDICAL_SERVICES = 'MEDICAL_SERVICES',
  PHYSICAL_GENERAL = 'PHYSICAL_GENERAL',
  GENERAL_CLOTHING = 'GENERAL_CLOTHING',
  CATERING = 'CATERING',
  GROCERY_FOOD = 'GROCERY_FOOD',
  LEASES_AND_RENTALS_MOTOR_VEHICLES = 'LEASES_AND_RENTALS_MOTOR_VEHICLES',
  LEASES_AND_RENTALS_TANGIBLE_MEDIA_PROPERTY = 'LEASES_AND_RENTALS_TANGIBLE_MEDIA_PROPERTY',
  MACHINERY = 'MACHINERY',
  RAW_MATERIALS = 'RAW_MATERIALS',
  UTILITIES_FUEL = 'UTILITIES_FUEL',
  MEDICAL_DEVICES = 'MEDICAL_DEVICES',
  MEDICINES = 'MEDICINES',
  NEWSPAPERS = 'NEWSPAPERS',
  PERIODICALS = 'PERIODICALS',
  GENERAL_OCCASIONAL_SALES = 'GENERAL_OCCASIONAL_SALES',
  MOTOR_VEHICLES_OCCASIONAL_SALES = 'MOTOR_VEHICLES_OCCASIONAL_SALES',
  GENERAL_OPTIONAL_MAINTENANCE_CONTRACTS = 'GENERAL_OPTIONAL_MAINTENANCE_CONTRACTS',
  PARTS_PURCHASED_OPTIONAL_MAINTENANCE_CONTRACTS = 'PARTS_PURCHASED_OPTIONAL_MAINTENANCE_CONTRACTS',
  GENERAL_POLLUTION_CONTROL_EQUIPMENT = 'GENERAL_POLLUTION_CONTROL_EQUIPMENT',
  GENERAL_TRADE_INS = 'GENERAL_TRADE_INS',
  FOOD_VENDING_MACHINE = 'FOOD_VENDING_MACHINE',
  MERCHANDISE_VENDING_MACHINE = 'MERCHANDISE_VENDING_MACHINE',
  SUPPLEMENTS = 'SUPPLEMENTS',
  SHIPPING = 'SHIPPING',
  GIFT_CARD = 'GIFT_CARD',
  CREDIT_CARD_SURCHARGES = 'CREDIT_CARD_SURCHARGES',
  CREDIT_CARD_FEES = 'CREDIT_CARD_FEES',
  MISCELLANEOUS_EXEMPT = 'MISCELLANEOUS_EXEMPT',
  DISCOUNT = 'DISCOUNT',
}

export type BulkApproveSource = 'categorizeAndApprove' | 'bulkApprove';

export type ProductStatusVariant = {
  variant: 'approved' | 'pending' | 'partial';
  title: string;
};

export const ProductUpdateSchema = object().shape({
  id: string().required(),
  external_id: string().required('ID is required'),
  name: string().required('Name is required'),
  description: string().optional().nullable(),
  product_category: string()
    .required('Category is required')
    .matches(/^(?!UNKNOWN).*$/g, 'Category can not be UNKNOWN'),
  product_subcategory: string()
    .required('Subcategory is required')
    .matches(/^(?!UNKNOWN).*$/g, 'Subcategory can not be UNKNOWN'),
  tax_exempt: boolean().optional(),
  status: mixed<ProductStatusEnum>().oneOf(Object.values(ProductStatusEnum)).required(),
  classification_failed: boolean().optional(),
});

export const ProductCreateSchema = ProductUpdateSchema.omit(['id']);
export type ProductCreate = InferType<typeof ProductCreateSchema>;
export type ProductUpdate = InferType<typeof ProductUpdateSchema>;

/**
 * ProductSubCategoryRead
 * @property {string} name
 * @property {string} description
 * @property {string} example
 */
export type ProductSubCategoryRead = {
  name: ProductSubCategoryEnum;
  description: string;
  example: string;
};

export type ProductSubCategoryReadWithTitle = ProductSubCategoryRead & { title: string };

/**
 * ProductCategoryRead
 * @property {string} name
 * @property {string} description
 * @property {ProductSubCategoryRead[]} subcategories
 */
export type ProductCategoryRead = {
  name: ProductCategoryEnum;
  description: string;
  subcategories: ProductSubCategoryRead[];
};

export type ProductCategoryReadWithTitle = ProductCategoryRead & { title: string };

export type ProductSummaryRead = {
  summary: {
    statuses: ProductStatusEnum[];
    status_totals: {
      [key in ProductStatusEnum]: number;
    };
    categories: ProductCategoryEnum[];
    category_totals: {
      [key in ProductCategoryEnum]: number;
    };
    subcategories: ProductSubCategoryEnum[];
    subcategory_totals: {
      [key in ProductSubCategoryEnum]: number;
    };
    sources: SourceEnum[];
    source_totals: {
      [key in SourceEnum]: number;
    };
  };
};

export type ProductCategorySubcategoryOverview = {
  category: ProductCategoryEnum;
  subcategory: ProductSubCategoryEnum;
  total_products: number;
};

export const ProductCategoryUpdateBaseValidationSchema = object().shape({
  new_category: mixed<ProductCategoryEnum>()
    .oneOf(Object.values(ProductCategoryEnum), 'Invalid category')
    .required('Category is required')
    .notOneOf([ProductCategoryEnum.UNKNOWN], 'Category cannot be UNKNOWN'),
  new_subcategory: mixed<ProductSubCategoryEnum>()
    .oneOf(Object.values(ProductSubCategoryEnum), 'Invalid subcategory')
    .required('Subcategory is required')
    .notOneOf([ProductSubCategoryEnum.UNKNOWN], 'Subcategory cannot be UNKNOWN'),
});

export const ProductCategorizeAndApprovedValidationSchema = ProductCategoryUpdateBaseValidationSchema.clone();
export type ProductCategorizeAndApprovedValidationType = InferType<typeof ProductCategorizeAndApprovedValidationSchema>;

export const ProductCategoryUpdateValidationSchema = ProductCategoryUpdateBaseValidationSchema.shape({
  status_list: array().of(mixed<ProductStatusEnum>().oneOf(Object.values(ProductStatusEnum), 'Invalid status')),
});

export type ProductCategoryUpdateValidationType = InferType<typeof ProductCategoryUpdateValidationSchema>;
export type ProductCategoryUpdate = ProductCategoryUpdateValidationType & {
  existing_category: string;
  existing_subcategory: string;
};

export type ProductCategoryCategorizeAndApproveType = {
  id: string;
  product_category: ProductCategoryEnum;
  product_subcategory: ProductSubCategoryEnum;
};

export type ProductConfigsType = {
  id: string;
  primary_product_category: string;
  primary_product_subcategory: string;
  product_description: string | null | undefined;
  ai_enabled: boolean;
};

export const ProductConfigsValidationSchema = object().shape({
  product_configs: array()
    .of(
      object().shape({
        id: string(),
        primary_product_category: string()
          .required('Category is required')
          .matches(/^(?!UNKNOWN).*$/g, 'Category can not be UNKNOWN'),
        primary_product_subcategory: string()
          .required('Subcategory is required')
          .matches(/^(?!UNKNOWN).*$/g, 'Subcategory can not be UNKNOWN'),
        product_description: string().nullable(),
        ai_enabled: boolean().required('AI enabled is required'),
      })
    )
    .required('At least one config is required.'),
});
export type ProductConfigsValidationType = InferType<typeof ProductConfigsValidationSchema>;
export type ProductConfigsCreateType = {
  product_configs: Omit<ProductConfigsValidationType['product_configs'][0], 'id'>[];
};
