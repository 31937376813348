import { ProductCategoryEnum } from '_api-client';
import { Box, createListCollection, Flex, IconButton, Table, Text, VStack } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { PRODUCT_STATE_KEY, updateProducts } from 'apis/product-apis';
import { KebabIcon } from 'components/icons';
import { Button } from 'components/ui/button';
import { Checkbox } from 'components/ui/checkbox';
import { Field } from 'components/ui/field';
import { MenuContent, MenuItem, MenuRoot, MenuTrigger } from 'components/ui/menu';
import {
  SelectContent,
  SelectItem,
  SelectRoot,
  SelectTrigger,
  SelectValueText,
  SubCategorySelectItem,
} from 'components/ui/select';
import { Tooltip } from 'components/ui/tooltip';
import { useFormik } from 'formik';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useOrg } from 'hooks/useOrg';
import useTracking from 'hooks/useTracking';
import { useEffect, useMemo } from 'react';
import { ProductStatusEnum, ProductSubCategoryEnum } from 'schema/types-schema.d';
import { ProductCategoryReadWithTitle, ProductSubCategoryReadWithTitle, ProductUpdate } from 'types/products';
import { ProductInstance } from 'types/shared-types';
import { getSource } from 'utils/enum-helpers';
import {
  getProductCategoryTitle,
  getProductSubcategoryTitle,
  getTaxExemptTextBySubCategory,
  getTaxExemptTextByTaxExempt,
  isValidCategory,
  isValidSubcategory,
} from 'utils/product-utils';
import * as Yup from 'yup';

import ProductStatusBadge from './product-status-badge';

const validationSchema = Yup.object().shape({
  product_category: Yup.string()
    .required('Category is required')
    .matches(/^(?!UNKNOWN).*$/g, 'Category can not be UNKNOWN'),
  product_subcategory: Yup.string()
    .required('Subcategory is required')
    .matches(/^(?!UNKNOWN).*$/g, 'Subcategory can not be UNKNOWN'),
  tax_exempt: Yup.boolean().optional(),
});

type ProductTableRowProps = {
  data: ProductInstance;
  categoriesData: {
    categories: ProductCategoryReadWithTitle[];
    subcategoriesByCategory: Record<ProductCategoryEnum, ProductCategoryReadWithTitle>;
  };
  onSelectChange: any;
  onSelectRowData: (data: ProductInstance | undefined) => void;
  visibleColumns: string[];
  onApproveStatusChange: (id: string, canApprove: boolean) => void;
  onOpenEditModal: () => void;
  checkedRows?: string[];
  enableSelection?: boolean;
  isSelected?: boolean;
  onRowCheck?: (selectedIds: string[]) => void;
  getRowId?: (row: any) => string;
};

const ProductTableRow = ({
  data,
  categoriesData,
  visibleColumns,
  onSelectChange,
  onSelectRowData,
  onOpenEditModal,
  onApproveStatusChange,
  //checkbox
  enableSelection,
  isSelected,
  onRowCheck,
  getRowId,
  checkedRows = [],
}: ProductTableRowProps) => {
  const { orgId } = useOrg();
  const queryClient = useQueryClient();
  const { handleSuccessNotification } = useHandleNotification();
  const { track } = useTracking();
  const {
    id,
    name,
    description,
    product_category,
    product_subcategory,
    source,
    status,
    tax_exempt,
    external_id,
    classification_failed,
  } = data;
  const isApproved = status === ProductStatusEnum.APPROVED;
  const { categories, subcategoriesByCategory } = categoriesData;

  const mutation = useMutation({
    mutationFn: (payload: ProductUpdate) => {
      return updateProducts(id, orgId, payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [PRODUCT_STATE_KEY] });
      handleSuccessNotification('Product updated successfully.');
    },
  });

  const { values, setValues, validateForm, handleSubmit, setFieldValue, errors, touched, dirty } = useFormik({
    initialValues: {
      product_category: product_category,
      product_subcategory: product_subcategory,
      tax_exempt: tax_exempt,
      classification_failed: classification_failed ?? false,
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: values => {
      mutation.mutate({
        ...values,
        id,
        name,
        external_id,
        description: description ?? null,
        status: ProductStatusEnum.APPROVED,
        tax_exempt: values.product_subcategory === ProductSubCategoryEnum.MISCELLANEOUS_EXEMPT,
      });
      track('approves product', { action: 'product' });
    },
  });

  const taxExemptText = dirty
    ? getTaxExemptTextBySubCategory(values.product_subcategory)
    : getTaxExemptTextByTaxExempt(values.tax_exempt);

  const disableApproveButton =
    !values.product_category ||
    !values.product_subcategory ||
    values.product_category === ProductCategoryEnum.UNKNOWN ||
    values.product_subcategory === ProductSubCategoryEnum.UNKNOWN;

  useEffect(() => {
    const canApprove =
      status !== ProductStatusEnum.APPROVED &&
      isValidCategory(values.product_category) &&
      isValidSubcategory(values.product_subcategory);

    onApproveStatusChange(id, canApprove);
  }, [disableApproveButton]);

  const handleFormikValueChange = (fieldName: string, value: any) => {
    const newValues = {
      ...values,
      name: name,
      description: description,
      status: ProductStatusEnum.APPROVED,
      [fieldName]: value,
    };
    validateForm();
    setValues(newValues);
    onSelectChange(id, newValues);
  };

  const getSubcategoryItemsByCategoryId = (categoryId: ProductCategoryEnum) => {
    const subcategoryItems =
      subcategoriesByCategory && subcategoriesByCategory[categoryId]
        ? subcategoriesByCategory[categoryId].subcategories
        : [];
    if (categoryId === ProductCategoryEnum.UNKNOWN) {
      subcategoryItems.push({
        name: ProductSubCategoryEnum.UNKNOWN,
        description: '',
        example: '',
      });
    }
    return subcategoryItems as unknown as ProductSubCategoryReadWithTitle[];
  };

  const categoriesCollection = useMemo(() => {
    return createListCollection({
      items: categories?.concat([
        { name: ProductCategoryEnum.UNKNOWN, title: 'Unknown', description: '', subcategories: [] },
      ]),
      itemToString: ({ title }) => title,
      itemToValue: ({ name }) => name,
    });
  }, [categories]);

  const subCategoriesCollection = useMemo(() => {
    return createListCollection({
      items: getSubcategoryItemsByCategoryId(values.product_category),
      itemToString: ({ title }) => title,
      itemToValue: ({ name }) => name,
    });
  }, [getSubcategoryItemsByCategoryId]);

  return (
    <Table.Row>
      {enableSelection && (
        <Table.Cell padding="0 0 0 16px">
          {status !== ProductStatusEnum.APPROVED ? (
            <Checkbox
              checked={isSelected}
              onCheckedChange={({ checked }) => {
                const id = getRowId?.(data);
                if (!id || !onRowCheck) return;
                if (checked) {
                  onRowCheck([...checkedRows, id]);
                } else {
                  onRowCheck(checkedRows.filter((rowId: string) => rowId !== id));
                }
              }}
            />
          ) : (
            <></>
          )}
        </Table.Cell>
      )}
      {visibleColumns?.includes('NAME') && (
        <Table.Cell verticalAlign={'center'}>
          <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
            <Text lineClamp={3}>{name}</Text>
          </Flex>
        </Table.Cell>
      )}
      {visibleColumns?.includes('DESCRIPTION') && (
        <Table.Cell width={{ sm: '240px' }}>
          <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
            <Tooltip content={description} disabled={!description || description.length <= 24}>
              <Text
                lineClamp={2}
                maxW="170px"
                aria-label={description ? `Product description: ${description}` : 'No description available'}
              >
                {description}
              </Text>
            </Tooltip>
          </Flex>
        </Table.Cell>
      )}
      {visibleColumns?.includes('CATEGORY') && (
        <Table.Cell verticalAlign={status === ProductStatusEnum.APPROVED ? 'center' : 'top'}>
          {status !== ProductStatusEnum.APPROVED ? (
            <Field
              invalid={!!(errors.product_category && touched.product_category)}
              errorText={errors.product_category}
            >
              <SelectRoot
                collection={categoriesCollection}
                value={values.product_category ? [values.product_category] : undefined}
                onValueChange={({ value }) => {
                  handleFormikValueChange('product_category', value[0]);
                  setFieldValue('product_subcategory', null);
                }}
              >
                <SelectTrigger>
                  <SelectValueText placeholder="Select" />
                </SelectTrigger>
                <SelectContent>
                  {categoriesCollection.items.map(item => (
                    <SelectItem key={item.name} item={item}>
                      {getProductCategoryTitle(item.name)}
                    </SelectItem>
                  ))}
                </SelectContent>
              </SelectRoot>
            </Field>
          ) : (
            <Text pl={1}>{getProductCategoryTitle(product_category)}</Text>
          )}
        </Table.Cell>
      )}

      {visibleColumns?.includes('SUBCATEGORY') && (
        <Table.Cell verticalAlign={status === ProductStatusEnum.APPROVED ? 'center' : 'top'}>
          {status !== ProductStatusEnum.APPROVED ? (
            <Field
              invalid={!!(errors.product_subcategory && touched.product_subcategory)}
              errorText={errors.product_subcategory}
            >
              <SelectRoot
                colorPalette="blue"
                collection={subCategoriesCollection}
                value={values.product_subcategory ? [values.product_subcategory] : undefined}
                onValueChange={({ value }) => {
                  handleFormikValueChange('product_subcategory', value[0]);
                }}
              >
                <SelectTrigger>
                  <SelectValueText placeholder="Select" />
                </SelectTrigger>
                <SelectContent>
                  {subCategoriesCollection.items.map(item => (
                    <SubCategorySelectItem
                      tooltip={
                        <VStack p={'8px'} gap={4}>
                          <Box w="full">
                            <Text fontSize={'xs'}>{!!item.description && 'Description:'}</Text>
                            <Text fontSize={'xs'}>{item.description}</Text>
                          </Box>
                          <Box w="full">
                            <Box minW={'60px'} mr={'30px'}>
                              <Text fontSize={'xs'}>{!!item.example && 'Examples:'}</Text>
                              <Text fontSize={'xs'}>{item.example}</Text>
                            </Box>
                          </Box>
                        </VStack>
                      }
                      key={item.name}
                      item={item}
                    >
                      {getProductSubcategoryTitle(item.name)}
                    </SubCategorySelectItem>
                  ))}
                </SelectContent>
              </SelectRoot>
            </Field>
          ) : (
            <Text pl={1}>{getProductSubcategoryTitle(product_subcategory)}</Text>
          )}
        </Table.Cell>
      )}

      {visibleColumns?.includes('SOURCE') && (
        <Table.Cell>
          <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
            <Text>{getSource(source)}</Text>
          </Flex>
        </Table.Cell>
      )}

      {visibleColumns?.includes('TAX EXEMPT') && (
        <Table.Cell width={{ sm: '100px' }} verticalAlign={'center'}>
          <Text color="gray.900">{taxExemptText}</Text>
        </Table.Cell>
      )}

      {visibleColumns?.includes('STATUS') && (
        <Table.Cell>
          <ProductStatusBadge status={status} />
        </Table.Cell>
      )}

      <Table.Cell width={'110px'}>
        <Flex justifyContent={'center'}>
          {isApproved ? (
            <MenuRoot>
              <MenuTrigger asChild>
                <IconButton variant="transparent-with-icon">
                  <KebabIcon size="lg" />
                </IconButton>
              </MenuTrigger>
              <MenuContent>
                <MenuItem
                  value="edit"
                  onClick={() => {
                    onSelectRowData(data);
                    onOpenEditModal();
                  }}
                >
                  Edit
                </MenuItem>
              </MenuContent>
            </MenuRoot>
          ) : (
            <Button
              loading={mutation.isPending}
              w={'80px'}
              variant={'outline'}
              colorPalette={'blue'}
              onClick={() => handleSubmit()}
              disabled={disableApproveButton}
            >
              Approve
            </Button>
          )}
        </Flex>
      </Table.Cell>
    </Table.Row>
  );
};

export default ProductTableRow;
