import { ProductCategoryEnum, ProductStatusEnum } from '_api-client';
import { Flex, Icon, IconButton, Skeleton, Table, Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import {
  getProductCategorySubcategoriesOverview,
  PRODUCT_CATEGORY_SUBCATEGORIES_OVERVIEW_STATE_KEY,
} from 'apis/product-apis';
import { ArrowLeft } from 'components/icons';
import { KDataTable } from 'components/table/data-table';
import { TableEmptyState } from 'components/table-empty-state/table-empty-state';
import { Button } from 'components/ui/button';
import { DrawerBackdrop, DrawerBody, DrawerContent, DrawerHeader, DrawerRoot, DrawerTitle } from 'components/ui/drawer';
import { useOrg } from 'hooks/useOrg';
import { useState } from 'react';
import { ProductCategoryReadWithTitle, ProductCategorySubcategoryOverview } from 'types/products';
import { getProductCategoryTitle, getProductSubcategoryTitle } from 'utils/product-utils';

import { ClassifierUpdateModal } from './classifier-update-modal';
import { FilterButton } from './filter-button';

type CategoryOverviewProps = {
  isOpen: boolean;
  onClose: () => void;
  categories: ProductCategoryReadWithTitle[];
  subcategoriesByCategory: Record<ProductCategoryEnum, ProductCategoryReadWithTitle>;
};

const TABLE_HEADERS = ['Category', 'Subcategory', 'Number of Products'];

const CategoryOverview = ({ isOpen, onClose, categories, subcategoriesByCategory }: CategoryOverviewProps) => {
  const { orgId } = useOrg();

  const [selectedStatus, setSelectedStatus] = useState<ProductStatusEnum[] | undefined>(undefined);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [selectedOverviewData, setSelectedOverviewData] = useState<ProductCategorySubcategoryOverview | null>(null);

  const { data: overviewData, isPending: isOverviewDataPending } = useQuery({
    queryKey: [PRODUCT_CATEGORY_SUBCATEGORIES_OVERVIEW_STATE_KEY, orgId, selectedStatus],
    queryFn: () =>
      getProductCategorySubcategoriesOverview({ orgId, params: { status__in: selectedStatus?.join(',') } }),
  });

  return (
    <DrawerRoot
      open={isOpen}
      onOpenChange={({ open }) => {
        if (!open) {
          onClose();
        }
      }}
      size={'xl'}
    >
      <DrawerBackdrop />
      <DrawerContent>
        <DrawerHeader display={'flex'} justifyContent={'space-between'}>
          <Flex gap={2} alignItems={'center'} justifyContent={'flex-start'}>
            <IconButton
              minW={6}
              h={6}
              rounded={'sm'}
              size="xs"
              variant={'ghost'}
              aria-label="back-to-filings"
              onClick={onClose}
            >
              <Icon w={6} h={6}>
                <ArrowLeft />
              </Icon>
            </IconButton>
            <DrawerTitle>Overview</DrawerTitle>
          </Flex>
          <FilterButton status={selectedStatus || undefined} setStatus={setSelectedStatus} />
        </DrawerHeader>
        <DrawerBody>
          {isOverviewDataPending ? (
            <Skeleton height="100%" />
          ) : (
            <>
              {overviewData?.data.length === 0 ? (
                <TableEmptyState tableName="Product Overview" />
              ) : (
                <KDataTable headers={TABLE_HEADERS}>
                  {overviewData?.data.map((data: ProductCategorySubcategoryOverview) => {
                    return (
                      <OverviewTableRow
                        key={`${data.category}-${data.subcategory}`}
                        data={data}
                        setIsUpdateModalOpen={setIsUpdateModalOpen}
                        setSelectedOverviewData={setSelectedOverviewData}
                      />
                    );
                  })}
                </KDataTable>
              )}
            </>
          )}
        </DrawerBody>
      </DrawerContent>

      {isUpdateModalOpen && selectedOverviewData && (
        <ClassifierUpdateModal
          isOpen={isUpdateModalOpen}
          onClose={() => setIsUpdateModalOpen(false)}
          existingData={selectedOverviewData}
          categories={categories}
          subcategoriesByCategory={subcategoriesByCategory}
        />
      )}
    </DrawerRoot>
  );
};
export default CategoryOverview;

const OverviewTableRow = ({
  data,
  setIsUpdateModalOpen,
  setSelectedOverviewData,
}: {
  data: ProductCategorySubcategoryOverview;
  setIsUpdateModalOpen: (value: boolean) => void;
  setSelectedOverviewData: (value: ProductCategorySubcategoryOverview) => void;
}) => {
  return (
    <Table.Row>
      <Table.Cell>
        <Text>{getProductCategoryTitle(data.category)}</Text>
      </Table.Cell>
      <Table.Cell>
        <Text>{getProductSubcategoryTitle(data.subcategory)}</Text>
      </Table.Cell>
      <Table.Cell>
        <Text>{data.total_products}</Text>
      </Table.Cell>
      <Table.Cell width="1.625rem">
        <Button
          variant="outline"
          colorScheme="primary"
          size="sm"
          onClick={() => {
            setIsUpdateModalOpen(true);
            setSelectedOverviewData(data);
          }}
        >
          Modify
        </Button>
      </Table.Cell>
    </Table.Row>
  );
};
