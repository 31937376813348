import {
  Box,
  createListCollection,
  Flex,
  HStack,
  IconButton,
  Separator,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { doProductsClassificationConfigs, PRODUCT_CLASSIFICATION_STATE_KEY } from 'apis/product-apis';
import { AIIconButton, RemoveIcon } from 'components/icons';
import { Button } from 'components/ui/button';
import {
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
} from 'components/ui/dialog';
import { Field } from 'components/ui/field';
import {
  SelectContent,
  SelectItem,
  SelectRoot,
  SelectTrigger,
  SelectValueText,
  SubCategorySelectItem,
} from 'components/ui/select';
import { FieldArray, Form, Formik } from 'formik';
import { useMemo } from 'react';
import {
  ProductCategoryEnum,
  ProductCategoryReadWithTitle,
  ProductConfigsCreateType,
  ProductConfigsType,
  ProductConfigsValidationSchema,
  ProductConfigsValidationType,
  ProductSubCategoryReadWithTitle,
} from 'types/products';
import { getProductSubcategoryTitle } from 'utils/product-utils';

type ProductClassifierConfigModalProps = {
  orgId: string;
  isOpen: boolean;
  onClose: () => void;
  categories: ProductCategoryReadWithTitle[];
  subcategoriesByCategory: Record<ProductCategoryEnum, ProductCategoryReadWithTitle>;
  onSubmit: () => Promise<void>;
  isClassifyPending: boolean;
  productConfig?: ProductConfigsType[];
};

const initialProductClassifierItem = {
  id: '',
  primary_product_category: '',
  primary_product_subcategory: '',
  product_description: null,
  ai_enabled: true,
};

export const ProductClassifierConfigModal = ({
  orgId,
  isOpen,
  onClose,
  categories,
  subcategoriesByCategory,
  onSubmit,
  productConfig,
  isClassifyPending,
}: ProductClassifierConfigModalProps) => {
  const queryClient = useQueryClient();

  const initialValues: ProductConfigsValidationType = {
    product_configs: [initialProductClassifierItem],
  };

  const { isPending: isCreatePending, mutateAsync: doCreateProductConfigs } = useMutation({
    mutationFn: async (values: ProductConfigsCreateType) => {
      await doProductsClassificationConfigs(orgId, values);
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: [PRODUCT_CLASSIFICATION_STATE_KEY, orgId, 'product', 'config'] });
      await onSubmit(); // after product config run classification
    },
  });

  const categoriesCollection = useMemo(() => {
    return createListCollection({
      items: categories,
      itemToString: ({ title }) => title,
      itemToValue: ({ name }) => name,
    });
  }, [categories]);

  const getSubcategoryItemsByCategoryId = (category: ProductCategoryEnum) => {
    const subCategoryObj = subcategoriesByCategory?.[category] as ProductCategoryReadWithTitle;
    return (subCategoryObj?.subcategories as ProductSubCategoryReadWithTitle[]) ?? [];
  };

  const getSubcategoriesCollection = (categoryId: ProductCategoryEnum) => {
    return createListCollection({
      items: getSubcategoryItemsByCategoryId(categoryId),
      itemToString: ({ title }: { title: string }) => title,
      itemToValue: ({ name }: { name: string }) => name,
    });
  };

  const handleFormSubmit = async (values: ProductConfigsValidationType) => {
    const newItemsPayload = {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      product_configs: values.product_configs?.map(({ id, ...rest }) => ({
        ...rest,
        product_description: rest.product_description ?? null,
      })),
    };

    try {
      if (productConfig?.length) {
        await onSubmit();
      } else {
        await doCreateProductConfigs(newItemsPayload);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <DialogRoot
      open={isOpen}
      onOpenChange={({ open }) => {
        if (!open) {
          onClose();
        }
      }}
      size={'md'}
      closeOnEscape={!(isCreatePending || isClassifyPending)}
      closeOnInteractOutside={!(isCreatePending || isClassifyPending)}
      restoreFocus={false}
    >
      <DialogBackdrop />
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Classify your products</DialogTitle>
        </DialogHeader>
        <DialogCloseTrigger />
        <DialogBody>
          <Text fontWeight={'medium'} pb={4}>
            Please share the below information about the primary products/services you sell.
          </Text>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={ProductConfigsValidationSchema}
            onSubmit={handleFormSubmit}
          >
            {({ values, setFieldValue, handleChange, handleSubmit, isValid, dirty }) => (
              <Form onSubmit={handleSubmit}>
                <FieldArray name="product_configs">
                  {({
                    insert,
                    remove,
                    form: {
                      values: { product_configs },
                      errors,
                    },
                  }) => (
                    <>
                      {product_configs?.map((_: ProductConfigsValidationType['product_configs'][0], index: number) => (
                        <Box key={index}>
                          <HStack align={'baseline'} mt={2}>
                            <Field
                              label="Category"
                              invalid={!!(errors as any)?.product_configs?.[index]?.primary_product_category}
                              errorText={(errors as any)?.product_configs?.[index]?.primary_product_category}
                              required
                            >
                              <SelectRoot
                                collection={categoriesCollection}
                                value={
                                  values.product_configs[index].primary_product_category
                                    ? [values.product_configs[index].primary_product_category]
                                    : undefined
                                }
                                onValueChange={({ value }) => {
                                  setFieldValue(`product_configs.${index}.primary_product_category`, value[0]);
                                  setFieldValue(`product_configs.${index}.primary_product_subcategory`, '');
                                }}
                              >
                                <SelectTrigger>
                                  <SelectValueText placeholder="Select" />
                                </SelectTrigger>
                                <SelectContent portalled={false}>
                                  {categoriesCollection.items.map(item => (
                                    <SelectItem key={item.name} item={item}>
                                      {item.title}
                                    </SelectItem>
                                  ))}
                                </SelectContent>
                              </SelectRoot>
                            </Field>

                            <Field
                              label="Subcategory"
                              invalid={!!(errors as any).product_configs?.[index]?.primary_product_subcategory}
                              required
                            >
                              <SelectRoot
                                collection={getSubcategoriesCollection(
                                  values.product_configs[index].primary_product_category as ProductCategoryEnum
                                )}
                                value={
                                  values.product_configs[index].primary_product_subcategory
                                    ? [values.product_configs[index].primary_product_subcategory]
                                    : undefined
                                }
                                onValueChange={({ value }) => {
                                  setFieldValue(`product_configs.${index}.primary_product_subcategory`, value[0]);
                                }}
                              >
                                <SelectTrigger>
                                  <SelectValueText placeholder="Select" />
                                </SelectTrigger>
                                <SelectContent
                                  portalled={false}
                                  minWidth={'196px'}
                                  maxWidth={'100%'}
                                  justifyContent={'flex-start'}
                                >
                                  {getSubcategoriesCollection(
                                    values.product_configs[index].primary_product_category as ProductCategoryEnum
                                  ).items.map(item => (
                                    <SubCategorySelectItem
                                      tooltip={
                                        <VStack p={'8px'} gap={4}>
                                          <Box w="full">
                                            <Text fontSize={'xs'}>{!!item.description && 'Description:'}</Text>
                                            <Text fontSize={'xs'}>{item.description}</Text>
                                          </Box>
                                          <Box w="full">
                                            <Box minW={'60px'} mr={'30px'}>
                                              <Text fontSize={'xs'}>{!!item.example && 'Examples:'}</Text>
                                              <Text fontSize={'xs'}>{item.example}</Text>
                                            </Box>
                                          </Box>
                                        </VStack>
                                      }
                                      key={item.name}
                                      item={item}
                                    >
                                      {getProductSubcategoryTitle(item.name)}
                                    </SubCategorySelectItem>
                                  ))}
                                </SelectContent>
                              </SelectRoot>
                            </Field>

                            {index > 0 && (
                              <VStack minW={'20px'} gap={5}>
                                <Box />
                                <IconButton
                                  variant={'transparent-with-icon'}
                                  size={'md'}
                                  onClick={() => {
                                    remove(index);
                                  }}
                                  aria-label="Remove"
                                >
                                  <RemoveIcon />
                                </IconButton>
                              </VStack>
                            )}
                          </HStack>

                          <Field
                            label="Please describe the products/services relevant to this category-subcategory pair"
                            mt={4}
                          >
                            <Textarea
                              name={`product_configs.${index}.product_description`}
                              onChange={handleChange}
                              value={values.product_configs[index].product_description ?? ''}
                            />
                          </Field>

                          {index !== product_configs.length - 1 && <Separator orientation="horizontal" my={4} />}
                          {index === product_configs.length - 1 && (
                            <Box
                              fontWeight={500}
                              width={'4.625rem'}
                              height={'2rem'}
                              color="#4285F4"
                              my={4}
                              cursor="pointer"
                              textAlign={'center'}
                              onClick={() => {
                                insert(values.product_configs.length + 1, initialProductClassifierItem);
                              }}
                            >
                              + Add
                            </Box>
                          )}
                        </Box>
                      ))}

                      <DialogFooter p={0}>
                        <Flex gap={4} mt={4}>
                          <Button variant="outline" color="secondary" onClick={onClose} w={'95px'}>
                            Cancel
                          </Button>
                          <Button
                            variant="solid"
                            color="primary"
                            w={'95px'}
                            type="submit"
                            loading={isCreatePending}
                            disabled={!isValid || !dirty}
                            bgGradient={'linear(to-r, #04C3CC, #527BE4, #A373EB)'}
                          >
                            <AIIconButton />
                            Submit
                          </Button>
                        </Flex>
                      </DialogFooter>
                    </>
                  )}
                </FieldArray>
              </Form>
            )}
          </Formik>
        </DialogBody>
      </DialogContent>
    </DialogRoot>
  );
};
